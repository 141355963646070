var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-section',{staticClass:"background-tech-list",attrs:{"id":"k-d-a-tech-list","space":"20","top-inner-shadow":""}},[_c('div',{style:({ height: `${_vm.g_bLowerBr ? '80px' : '125px'}`, gap: '10px', maxHeight: '300px' }),attrs:{"id":"container"}},[_c('div',{staticClass:"photobanner"},_vm._l((_vm.m_arrTechnology),function(item,idx){return _c('img',{key:idx,style:({
          height: `${_vm.g_bLowerBr ? '80px' : '125px'}`,
          width: `${_vm.g_bLowerBr ? '100px' : '200px'}`,
          maxWidth: '200px',
          alignItems: 'center',
          maxHeight: '125px',
          padding: '5px 0px',
          objectFit: 'contain'
        }),attrs:{"src":item,"alt":""}})}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }