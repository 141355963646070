<template>
  <base-section id="k-d-a-tech-list" class="background-tech-list" space="20" top-inner-shadow>
    <div
      id="container"
      :style="{ height: `${g_bLowerBr ? '80px' : '125px'}`, gap: '10px', maxHeight: '300px' }"
    >
      <div class="photobanner">
        <img
          v-for="(item, idx) in m_arrTechnology"
          :key="idx"
          :src="item"
          :style="{
            height: `${g_bLowerBr ? '80px' : '125px'}`,
            width: `${g_bLowerBr ? '100px' : '200px'}`,
            maxWidth: '200px',
            alignItems: 'center',
            maxHeight: '125px',
            padding: '5px 0px',
            objectFit: 'contain'
          }"
          alt=""
        />
      </div>
    </div>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
export default {
  name: 'KDATechList',

  mixins: [BaseBreakpoint],

  computed: {
    m_arrTechnology() {
      const resultOriginal = [];
      const resultLoop = [];

      for (let i = 0; i < 14; i++) {
        resultOriginal.push(require(`@/assets/photos/tech-list-${i + 1}.png`));
      }

      for (let i = 0; i < 14; i++) {
        resultLoop.push(require(`@/assets/photos/tech-list-${i + 1}.png`));
      }

      return resultOriginal.concat(resultLoop);
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

#container {
  position: relative;
  overflow: hidden;
}

.photobanner {
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  white-space: nowrap;
  animation: bannermove 25s linear infinite;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.photobanner img {
  margin: 0 1.5rem;
}

@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
.background-tech-list {
  background-color: #fbfbfb;
}

.img-container {
  overflow: hidden;
  position: relative;
  height: 100%;
  position: relative;
  align-items: center;
}

.img-container-lower {
  position: relative;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
}
</style>
